import { NavLink, RouterLink, NavLinks, PrimaryLink } from "../headers/light.js";

export const links = [
  { label: 'ABOUT US', link: '/' },
  { label: 'LEASE CONVERSION', link: '/lease-conversion' },
  { label: 'MEMBER SERVICES', link: '/member-services' },
  { label: 'NETWORK', link: '/network' },
  { label: 'TOURS', link: '/tours' },
  { label: 'EVENTS', link: '/launch-events'},
  // { label: 'BLOG', link: 'https://www.solutionleaseclub.com/', external: true },
  { label: 'CONTACT US', link: '/contact-us' },
]

export default [
  <NavLinks key={1}>
    {
      links.slice(0, -1).map(x => (
        x.external ? (
          <NavLink key={x.link} target="_blank" href={x.link}>
            {x.label}
          </NavLink>
        )
        : (
          <RouterLink key={x.link} to={x.link}>
          {x.label}
        </RouterLink>
        )
      ))
    }
  </NavLinks>,
  <NavLinks key={2}>
    <PrimaryLink href="/contact-us">
      CONTACT US
    </PrimaryLink>
  </NavLinks>
];
