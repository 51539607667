import 'react-phone-number-input/style.css'
import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { useEmailAPI } from "components/utils/api";
import Select from 'react-select'
import { GENERAL, MEMBER_SERVICES, LEASE_TAKEOVERS, TOURS } from 'components/utils/categories'
import PhoneInput from 'react-phone-number-input'

const Alert = styled.div`
  ${tw`fixed py-2 px-4 bg-green-400 font-semibold rounded-md z-50`}

  left: 50%;
  transition: transform 100ms ease-in-out;
  transform: translate(-50%, -100%);
  top: 0;

  ${props => props.success ? css`transform: translate(-50%, 60px)` : ''}
`
const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`

const Form = styled.div`
  ${props => props.centered ? tw`md:mx-auto! max-w-md!` : ''}
  ${tw`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`}
`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

// const Form = tw.div`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`
const InputLabel = tw.p`text-left mt-6 mb-1`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`
const ErrorMessage = tw.p`text-red-600 text-sm mt-2`

const StyledPhoneInput = styled(PhoneInput)`
  --PhoneInputCountrySelectArrow-color: transparent;
  --PhoneInputCountryFlag-borderColor: transparent;
  --PhoneInputCountryFlag-borderColor--focus: transparent;
`

export default ({
  subheading = "Contact Us",
  heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr/> with us.</>,
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  submitButtonText = "Send",
  hideForm = false,
  centered = false,
  category: propCategory = GENERAL
}) => {
  const [onEmail, loading] = useEmailAPI()
  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [category, setCategory] = useState(propCategory)
  const [message, setMessage] = useState('')
  const [errors, setErrors] = useState({})

  const handleOnSubmit = async () => {
    let errs = {}
    
    if (!name) errs.name = 'Please provide a name'
    if (!email) errs.email = 'Please provide an email'
    if (!message) errs.message = 'Please write a message'
    if (Object.keys(errs).length) {
      // has errors
      setErrors(errs)
      return
    }
    
    try {
      await onEmail({
        title: 'Solution Lease Club Enquiry',
        email,
        phone,
        name,
        type: category.label,
        description: message,
      })

      setSuccess(true)
      setName('')
      setEmail('')
      setPhone('')
      setMessage('')
      setCategory(propCategory)
      const id = setTimeout(() => {
        setSuccess(false)
        clearTimeout(id)
      }, 3000)
    } catch (err) {
      console.log(err)
    }
  }

  const handleOnChange = (cb) => (e) => {
    const { name, value } = e.target

    cb(value)
    setErrors({
      ...errors,
      [name]: null,
    })
  }

  const options = [MEMBER_SERVICES, LEASE_TAKEOVERS, TOURS, GENERAL]

  return (
    <Container>
      <Alert success={success}>Message sent!</Alert>
      <TwoColumn>
        <Column>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            {
              !hideForm && (
                <Form centered={centered}>
                  <InputLabel tw="mt-0">Category</InputLabel>
                  <Select value={category} options={options} isDisabled={loading} onChange={(opt) => { setCategory(opt) }} />
                  <InputLabel>Email</InputLabel>
                  <Input disabled={loading} type="email" value={email} onChange={handleOnChange(setEmail)} name="email" placeholder="Your Email Address" />
                  <InputLabel>Phone - Optional</InputLabel>
                  <StyledPhoneInput
                    inputComponent={Input}
                    defaultCountry="CA"
                    name="phone"
                    disabled={loading}
                    placeholder="Your phone number"
                    value={phone}
                    onChange={(v) => {
                      setPhone(v)
                      setErrors({ ...errors, contact: null })
                    }}
                  />
                  {errors.contact && <ErrorMessage>{errors.contact}</ErrorMessage>}
                  <InputLabel>Name</InputLabel>
                  <Input disabled={loading} type="text" value={name} onChange={handleOnChange(setName)} name="name" placeholder="Full Name" />
                  {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
                  <InputLabel>Message</InputLabel>
                  <Textarea name="message" value={message} disabled={loading} onChange={handleOnChange(setMessage)} placeholder="Your Message Here" />
                  {errors.message && <ErrorMessage>{errors.message}</ErrorMessage>}
                  <SubmitButton disabled={loading || success} type="submit" onClick={handleOnSubmit}>
                    {loading ? 'Sending...' : submitButtonText}
                  </SubmitButton>
                </Form>
              )
            }
          </TextContent>
        </Column>
      </TwoColumn>
    </Container>
  );
};
