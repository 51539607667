import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { PrimaryLink } from "components/misc/Links.js";

import LogoImage from "images/logo.svg";
import smLogo from '../../images/LeaseClubLogo-sm.svg'
import lgLogo from '../../images/LeaseClubLogo-lg.svg'
import sfLogo from '../../images/sf-logo.png'
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";
import { ReactComponent as InstagramIcon } from "feather-icons/dist/icons/instagram.svg";
import { links } from 'components/utils/navlinks'

const Container = tw.div`relative bg-gray-200 text-gray-700 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const ContainerWithBG = styled(Container)`
  background-color: rgb(102, 102, 102);
`
const BackgroundImage = tw.img`absolute opacity-25 w-full max-w-none top-0 right-0 transform`
const Content = tw.div`max-w-screen-2xl mx-auto relative z-10`;
const ColumnContainer = tw.div`flex flex-wrap text-center sm:text-left justify-start -mt-12`;

const Column = tw.div`px-4 flex-1`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`text-white text-lg font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300`;

const SubscribeNewsletterColumn = tw(Column)`text-left`;
const SubscribeNewsletterContainer = tw.div`max-w-sm mx-auto lg:mx-0 `;
const SubscribeText = tw.p`mt-2 lg:mt-6 text-sm font-medium text-gray-600`;
const SubscribeForm = tw.form`mt-4 lg:mt-6 text-sm sm:flex max-w-xs sm:max-w-none mx-auto sm:mx-0`;
const Input = tw.input`bg-gray-300 px-6 py-3 rounded sm:rounded-r-none border-2 sm:border-r-0 border-gray-400 hover:border-primary-500 focus:outline-none transition duration-300 w-full`;
const SubscribeButton = tw(PrimaryButtonBase)`mt-4 sm:mt-0 w-full sm:w-auto rounded sm:rounded-l-none px-8 py-3`;

const Divider = tw.div`my-16 border-b-2 border-gray-300 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;
const Col = tw.div`flex-1`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const SFLogoContainer = tw(LogoContainer)`my-4`
const LogoImg = tw.img`w-1/2`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-800`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base font-medium text-white`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-md bg-primary-400 text-gray-100 hover:bg-gray-700 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

export default () => {
  return (
    <ContainerWithBG>
      <Content>
        <ColumnContainer>
          <Column>
            <LinkList>
              <LinkListItem>
                <SocialLinksContainer>
                  <SocialLink href="#">
                    <FacebookIcon />
                  </SocialLink>
                  <SocialLink href="#">
                    <InstagramIcon />
                  </SocialLink>
                </SocialLinksContainer>
              </LinkListItem>
              <LinkListItem>
                <PrimaryLink tw="text-white font-normal" href="mailto:enquiry@solution.financial">enquiry@solution.financial</PrimaryLink>
              </LinkListItem>
              <LinkListItem>
                <PrimaryLink tw="text-white font-normal" href="tel:+1(604)-233-1937">604-233-1937</PrimaryLink>
              </LinkListItem>
            </LinkList>
            <SFLogoContainer>
              <a href="https://www.solution.financial/" target="_blank" rel="noreferrer">
                <LogoImg tw="w-full max-w-72 mt-4" src={sfLogo} />
              </a>
            </SFLogoContainer>
          </Column>
          <Column>
            <LinkList>
              {
                links.slice(0, 4).map(x => (
                  <LinkListItem key={x.link}>
                    <Link href={x.link}>{x.label}</Link>
                  </LinkListItem>
                ))
              }
            </LinkList>
          </Column>
          <Column>
            <LinkList>
              {
                links.slice(4, links.length).map(x => (
                  <LinkListItem key={x.link}>
                    <Link href={x.link}>{x.label}</Link>
                  </LinkListItem>
                ))
              }
            </LinkList>
          </Column>
        </ColumnContainer>
        <Divider />
        <ThreeColRow>
          <Col />
          {/* <Col>
            <CopywrightNotice>&copy; {new Date().getFullYear()} Staccc Technologies. All Rights Reserved.</CopywrightNotice>
          </Col> */}
          <Col>
            <LogoContainer>
              <LogoImg tw="ml-auto" src={lgLogo} />
            </LogoContainer>
          </Col>
        </ThreeColRow>
      </Content>
    </ContainerWithBG>
  );
};
