import React, { useEffect, useState } from "react"

export const API = 'https://api.solutionfinancial.ca:8083'

export const useLeaseTakeovers = () => {
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const loadTakeovers = async () => {
      try {
        setLoading(true)
        let res = await fetch(`${API}/leaseTakeovers`, {
          method: 'GET',
        })

        if (res.ok) {
          res = await res.json()
          res.sort((a, b) => a.permutation - b.permutation)
          const leaseTakeovers = res.leaseTakeovers || res

          setList(leaseTakeovers)
        } else {
          throw new Error('No leaseTakeovers were found with')
        }
      } catch (err) {
        console.log(err)
      }
      setLoading(false)
    }

    loadTakeovers()
  }, [])

  return [list, loading]
}

export const useEmailAPI = () => {
  const [loading, setLoading] = useState(false)

  const onEmail = async (details) => {
    setLoading(true)
    try {
      let res = await fetch(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_TEST_API : process.env.REACT_APP_API}/emails/lcm`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(details),
      })

      if (res.ok) {
        res = await res.json()
        setLoading(false)

        return res
      } else {
        throw new Error('Please try again.')
      }
    } catch (err) {
      console.log(err)
    }

    setLoading(false)
  }

  return [onEmail, loading]
}
