import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import { Subheading } from "components/misc/Headings.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import navlinks from 'components/utils/navlinks'
import ResponsiveVideoEmbed from "helpers/ResponsiveVideoEmbed.js";
import NewImage from 'images/new.png'
import PartyImage from 'images/party.png'
import contentful from "components/utils/contentful";
import { ReactComponent as InstagramIcon } from "feather-icons/dist/icons/instagram.svg";
import { ReactComponent as YoutubeIcon } from "feather-icons/dist/icons/youtube.svg";

const Container = tw.div`relative my-10 md:my-20`
const Description = tw.p`text-secondary-100 mt-8 mb-12 font-medium text-center md:text-left md:text-lg max-w-screen-lg`
const VideoWrapper = tw.div`max-w-full lg:max-w-half w-full`
const IconWrapper = tw.div`flex my-4`
const Icon = tw.a`bg-primary-400 rounded-md p-2 ml-2 first:ml-0`
const SectionHeading = tw.div`flex justify-center sm:justify-between items-center`
const BackgroundImage = tw.img`absolute -z-10 bottom-1/2 right-1/2 transform translate-x-2/3 translate-y-2/3 opacity-50`

export default () => {
  const [videoLink, setVideoLink] = useState([])

  useEffect(() => {
    const getVideoLink = async () => {
      try {
        const res = await contentful.getEntries({
          content_type: 'link',
          'fields.id': 'lev-1',
        })

        setVideoLink(res.items[0].fields.link)
      } catch (err) {
        console.log(err)
      }
    }

    getVideoLink()
  }, [])

  return (
    <AnimationRevealPage disabled>
      <Header links={navlinks} />
      <Container tw="relative">
        <SectionHeading>
          <Subheading tw="text-2xl lg:text-4xl uppercase">Launch Events</Subheading>
          <img src={NewImage} alt="Launch Events" tw="w-10 h-10 md:w-16 md:h-16" />
        </SectionHeading>
        <Description>
          Solution Lease Club actively attends launch events for brands such as <b tw="text-secondary-300">Ferrari, Mercedes Benz, Lamborghini, and Porsche</b> to keep our staff educated on the latest trends in the automotive world. This allows us to provide our members with the latest details on what’s happening in the luxury and ultra-luxury automotive world. Follow us on social media to stay updated with the events that we attend.
        </Description>
        <VideoWrapper>
          <ResponsiveVideoEmbed url={videoLink} tw="mt-4 w-full" />
        </VideoWrapper>
        <IconWrapper>
          <Icon href="#" target="_blank" rel="noopener">
            <InstagramIcon tw="md:w-12 md:h-12" stroke="#fff" />
          </Icon>
          <Icon href="#" target="_blank" rel="noopener">
            <YoutubeIcon tw="md:w-12 md:h-12" stroke="#fff" />
          </Icon>
        </IconWrapper>
        <BackgroundImage width={550} src={PartyImage} />
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
