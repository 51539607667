import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import navlinks from 'components/utils/navlinks'
import TwoColWithTwoFeaturesAndButtons from "components/features/TwoColWithTwoFeaturesAndButtons";
import contentful from 'components/utils/contentful'
import ContactUsWrapper from 'components/misc/ContactUsWrapper'
import { LEASE_TAKEOVERS } from 'components/utils/categories'
import { PrimaryButton } from "components/misc/Buttons.js";

const Container = tw.div`mb-20 lg:my-20`

export default () => {
const [image, setImage] = useState(null)

  useEffect(() => {
    const getImage = async () => {
      try {
        const res = await contentful.getEntries({
          content_type: 'asset',
          'fields.id': 'lt-1',
        })

        setImage(res.items[0].fields.asset.fields.file.url)
      } catch (err) {
        console.log(err)
      }
    }

    getImage()
  }, [])

  return (
    <AnimationRevealPage disabled>
      <Header links={navlinks} />
      <Container>
        <TwoColWithTwoFeaturesAndButtons
          textOnLeft
          features={[]}
          image={image}
          subheading={null}
          linkText=""
          linkHref=""
          heading={<span tw="text-xl sm:text-2xl md:text-4xl text-primary-500">Lease Conversion</span>}
          description={
            <>
              <span>Solution Financial often has an inventory of leased vehicles on hand that are available to Lease Club members for takeover.</span>
              <br />
              <br />
              <span>Check out the available vehicles listed below and contact one of our <b tw="text-secondary-300">Sales professionals</b> if any are of interest to you, or if you have any questions.</span>
              <br />
              <br />
              <span><a tw="font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300" href="https://www.solution.financial/lease-takeover" target="_blank" rel="noreferrer">Lease Conversion Inventory</a></span>
            </>
          }
          ButtonComponent={(
            <ContactUsWrapper description="Connect with us to get access to our lease conversion services." category={LEASE_TAKEOVERS}>
                {
                  ({ open }) => (
                    <PrimaryButton onClick={() => { open(true) }}>Contact Sales</PrimaryButton>
                  )
                }
              </ContactUsWrapper>
          )}
        />
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
