export const GENERAL = {
  value: 'GENERAL',
  label: 'General',
}
export const MEMBER_SERVICES = {
  value: 'MEMBER_SERVICES',
  label: 'Member Services',
}
export const LEASE_TAKEOVERS = {
  value: 'LEASE_TAKEOVERS',
  label: 'Lease Takeovers/Conversions',
}
export const TOURS = {
  value: 'TOURS',
  label: 'Tours',
}