import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { Link } from 'react-router-dom'
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import LaunchEvents from 'images/clubServices/LaunchEvents.png'
import LeaseClubTours from 'images/clubServices/LeaseclubTours.png'
import LeaseTakeover from 'images/clubServices/LeaseTakeover.png'
import Network from 'images/clubServices/Network.png'
import Services from 'images/clubServices/Services.png'
import SampleBackground from 'images/SampleBackground.png'
import VectorBackground from 'images/Footer_Background.png'
import contentful from 'components/utils/contentful'
import navlinks from 'components/utils/navlinks'
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel'
import Header, { NavLink, NavLinks, RouterLink, LogoLink, NavToggle, DesktopNavLinks, MobileNavLinks } from "../headers/light.js";
import { useMediaQuery } from '@react-hook/media-query'

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
  ${DesktopNavLinks} ${NavLinks} ${RouterLink} {
    ${tw`text-white`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-4 -mt-4 sm:-mx-8 sm:-mt-8 bg-center bg-cover`}
  background-image: url(${VectorBackground});
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-10`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-2xl mx-auto`;
const TwoColumn = tw.div`pt-6 pb-12 flex justify-between items-stretch flex-col sm:flex-row`;
const Column = styled.div`
  ${tw`max-w-none first:mb-2 sm:first:mb-0 flex flex-1 flex-col sm:first:mr-2 sm:last:ml-2 items-center lg:block`}

  flex: 1.2;
`

const ServiceColumn = styled(Column)`
  ${tw`flex flex-col pb-6 flex-1 items-start justify-between`}
`

const ServiceLink = styled(Link)`
  ${tw`flex-1 self-stretch mb-2 last:mb-0`}
`

const ServicePanel = styled.div`
  ${tw`flex h-full items-center relative px-2 py-6`}

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${props => props.background});
    background-position: center;
    background-size: cover;
    filter: brightness(0.5);
  }

  span {
    ${tw`text-base md:text-2xl text-white font-bold z-10`}
  }

  img {
    ${tw`w-8 h-8 md:w-12 md:h-12 lg:w-16 lg:h-16 mr-4 z-10`}
  }
`
const CarouselContainer = tw.div`relative`
const SlideContent = styled.div`
  ${tw`relative h-full`}
`
const SlideImage = styled.img`
  ${tw`min-w-full h-full object-cover`}
`
const DotGroupWrapper = styled(DotGroup)`
  ${tw`flex justify-center mt-4`}

  & > button {
    ${tw`px-0 py-0 mx-1 border-0 rounded-full bg-gray-500`}
    height: 8px;
    width: 8px;
  }

  & > button[disabled] {
    ${tw`bg-primary-500`}
  },
`

const services = [
  {
    label: 'Lease Conversion',
    slug: 'lt-1',
    // link: 'https://www.solution.financial/lease-takeover',
    // external: true,
    link: '/lease-conversion',
    img: LeaseTakeover,
  },
  {
    label: 'Services',
    slug: 'pt-1',
    link: '/member-services',
    img: Services,
  },
  {
    label: 'Network',
    slug: 'nt-1',
    link: '/network',
    img: Network,
  },
  {
    label: 'LeaseClub Tours',
    slug: 'lct-1',
    link: '/tours',
    img: LeaseClubTours,
  },
  {
    label: 'Launch Events',
    slug: 'leo-1',
    link: '/launch-events',
    img: LaunchEvents,
  },
]

export default () => {
  const [slides, setSlides] = useState([])
  const [backgrounds, setBackgrounds] = useState([])
  const matchesMd = useMediaQuery('(max-width: 900px)')
  const matchesLg = useMediaQuery('(max-width: 1300px)')

  useEffect(() => {
    const getBackgrounds = async () => {
      try {
        const res = await contentful.getEntries({
          content_type: 'asset',
        })

        setBackgrounds(res.items.map(x => ({
          id: x.fields.id,
          label: x.fields.label,
          url: x.fields.asset.fields.file.url,
        })))
      } catch (err) {
        console.log(err)
      }
    }

    const getSlideshow = async () => {
      try {
        const res = await contentful.getEntries({
          content_type: 'homepageSlideshow',
        })

        const { slides } = res.items[0].fields

        setSlides(slides.map(x => ({
          id: x.sys.id,
          url: x.fields.file.url,
        })))
      } catch (err) {
        console.log(err)
      }
    }

    getSlideshow()
    getBackgrounds()
  }, [])

  const getBackground = (slug) => {
    const bg = backgrounds.find(bg => bg.id === slug)

    if (bg) return bg.url
    return SampleBackground
  }

  // [w,h]
  const getAspectRatio = () => {
    if (matchesMd) return [600, 800]
    if (matchesLg) return [600, 700]
    return [700,600]
  }

  const [slideW, slideH] = getAspectRatio()

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navlinks} />
        <TwoColumn>
          <Column tw="items-stretch hidden xs:block">
            <CarouselContainer>
              <CarouselProvider
                naturalSlideWidth={slideW}
                naturalSlideHeight={slideH}
                totalSlides={slides.length}
                visibleSlides={1}
                dragEnabled={false}
                interval={2500}
                infinite
                isPlaying
              >
                <Slider>
                  {
                    slides.map((x, i) => (
                      <Slide key={x.id} index={i}>
                        <SlideContent>
                          <SlideImage draggable={false} src={x.url} alt={x.url} />
                        </SlideContent>
                      </Slide>
                    ))
                  }
                </Slider>
                <DotGroupWrapper />
              </CarouselProvider>
            </CarouselContainer>
          </Column>
          <ServiceColumn>
              {
                services.map(x => (
                  <ServiceLink
                    to={x.link}
                    key={x.slug}
                  >
                    <ServicePanel background={getBackground(x.slug)}>
                      <img src={x.img} alt={x.label} />
                      <span>{x.label.toUpperCase()}</span>
                    </ServicePanel>
                  </ServiceLink>
                ))
              }
          </ServiceColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
