import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import navlinks from 'components/utils/navlinks'
import TwoColWithTwoFeaturesAndButtons from "components/features/TwoColWithTwoFeaturesAndButtons";
import contentful from 'components/utils/contentful'
import ContactUsWrapper from 'components/misc/ContactUsWrapper'
import { MEMBER_SERVICES } from 'components/utils/categories'
import { PrimaryButton } from "components/misc/Buttons.js";

import Pictorials from './Pictorials'

const Container = tw.div`mb-20 lg:my-20`

export default () => {
const [image, setImage] = useState(null)

  useEffect(() => {
    const getImage = async () => {
      try {
        const res = await contentful.getEntries({
          content_type: 'asset',
          'fields.id': 'ms-1',
        })

        setImage(res.items[0].fields.asset.fields.file.url)
      } catch (err) {
        console.log(err)
      }
    }

    getImage()
  }, [])

  return (
    <AnimationRevealPage disabled>
      <Header links={navlinks} />
      <Container>
        <TwoColWithTwoFeaturesAndButtons
          textOnLeft={false}
          features={[]}
          image={image}
          subheading={null}
          linkText="Previous Pictorials"
          linkHref="/pictorials"
          heading={<span tw="text-xl sm:text-2xl md:text-4xl text-primary-500">Member Services</span>}
          description={
            <>
              <span>Along with connecting our members to <b tw="text-secondary-300">Insurance professionals</b> and <b tw="text-secondary-300">car beauty shops</b>, we extend services to ensure that our clients can enjoy the most out of their vehicles.</span>
              <br />
              <br />
              <span>We offer Lease Club members access to <b tw="text-secondary-300">pictorial shoots with their cars</b>. Let us know how you want your photoshoot to look, and we will get you the results you desire.</span>
            </>
          }
          ButtonComponent={(
            <ContactUsWrapper description="Connect with us to get access to our member services." category={MEMBER_SERVICES}>
                {
                  ({ open }) => (
                    <PrimaryButton onClick={() => { open(true) }}>Schedule Appointment</PrimaryButton>
                  )
                }
              </ContactUsWrapper>
          )}
        />
      </Container>
      <Pictorials />
      <Footer />
    </AnimationRevealPage>
  );
};
