import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import FileSaver from "file-saver";
import { RouterLink } from 'components/misc/Links'
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import navlinks from 'components/utils/navlinks'
import { Subheading } from "components/misc/Headings.js";
import TwoColWithTwoFeaturesAndButtons from "components/features/TwoColWithTwoFeaturesAndButtons";
import NetworkImage from 'images/network.png'
import ContactUsImage from 'images/contact-us.png'
import Handshake from 'images/handshake.png'
import contentful from 'components/utils/contentful'
import { ReactComponent as ArrowRightCircle } from "feather-icons/dist/icons/arrow-right-circle.svg";
import { PrimaryButton } from "components/misc/Buttons.js";

const MoreInfoContainer = tw.div``
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto pb-20`;
const Column = tw.div`flex-1 w-full max-w-md mx-auto md:max-w-none md:mx-0`;

const Features = tw.div`mt-8 max-w-sm mx-auto md:mx-0`;
const Feature = tw.div`mt-4 flex items-center flex-row`;

const FeatureIconContainer = styled.div`
  svg {
    ${tw`w-8 h-8 stroke-1`}
  }
`;

const FeatureText = tw.div`mt-0 flex-1 md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`text-lg`;

const MemberNetworkGraphic = styled.div`
  ${tw`relative`}

  .handshake {
    ${tw`absolute top-0 right-0`}
  }
`

export default () => {
  const [brochure, setBrochure] = useState(null)

  useEffect(() => {
    const getImage = async () => {
      try {
        const res = await contentful.getEntries({
          content_type: 'asset',
          'fields.id': 'mb-1',
        })

        setBrochure(res.items[0].fields.asset.fields.file.url)
      } catch (err) {
        console.log(err)
      }
    }

    getImage()
  }, [])

  const onDownload = () => {
    try {
      if (brochure) {
        FileSaver.saveAs(brochure, 'Solution Lease Club Brochure.pdf')
      }
    } catch (err) {
      console.log(err)
    }
  }

  const features = [
    {
      Icon: ArrowRightCircle,
      title: "Marketing Services",
    },
    {
      Icon: ArrowRightCircle,
      title: "Customized Marketing Strategy",
    },
    {
      Icon: ArrowRightCircle,
      title: "Bi-weekly email updates to our network",
    },
  ]

  return (
    <AnimationRevealPage disabled>
      <Header links={navlinks} />
      <TwoColWithTwoFeaturesAndButtons
        features={[]}
        subheading={null}
        heading={<span tw="text-2xl md:text-3xl uppercase text-primary-500">LeaseClub Member Network</span>}
        description={
          <>
            <span>Sign up for our <b tw="text-secondary-300">Lease Club Business Owner services</b> to have your business featured on Solution Financial’s network across the globe. Get to know professionals from a vast number of industries who share a passion for luxury and exotic vehicles.</span>
            <br />
            <br />
            <span>In addition, Solution Lease Club also offers <b tw="text-secondary-300">custom Marketing Services</b> to help our members advertise and grow their business.</span>
          </>
        }
        linkText={null}
        ButtonComponent={(
          <PrimaryButton onClick={onDownload}>Download LeaseClub Marketing Package</PrimaryButton>
        )}
        showBGImage={false}
        imageComponent={(
          <MemberNetworkGraphic>
            <img src={NetworkImage} alt="LeaseClub Member Network" />
            <img className="handshake" src={Handshake} width={96} height={96} alt="LeaseClub Member Network" />
          </MemberNetworkGraphic>
        )}
      />
      <MoreInfoContainer>
        <TwoColumn>
          <Column>
            <Subheading tw="text-secondary-100 font-normal">For more information please <RouterLink to="/contact-us">contact us</RouterLink>.</Subheading>
            <img tw="mx-auto sm:mx-0" width={300} src={ContactUsImage} alt="Contact us for more information" />
          </Column>
          <Column>
            <Subheading tw="text-secondary-100 font-normal">Some services:</Subheading>
            <Features>
              {features.map((feature, index) => (
                <Feature key={index}>
                  <FeatureIconContainer>{<feature.Icon />}</FeatureIconContainer>
                  <FeatureText>
                    <FeatureHeading>{feature.title}</FeatureHeading>
                  </FeatureText>
                </Feature>
              ))}
            </Features>
          </Column>
        </TwoColumn>
      </MoreInfoContainer>
      <Footer />
    </AnimationRevealPage>
  );
};
