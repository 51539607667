import React, { useState } from 'react'
import styled from "styled-components";
import tw from "twin.macro";
import PropTypes from 'prop-types'
import ReactModalAdapter from "helpers/ReactModalAdapter";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed flex justify-center items-center inset-0 z-50`}

    transition: none;
    backdrop-filter: blur(2px) brightness(0.75);
  }
  &.mainHeroModal__content {
    ${tw`m-4 sm:m-16 px-4 sm:px-16 max-w-screen-xl absolute flex justify-center items-center rounded-lg bg-white shadow-raised outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;


const ContactUsWrapper = ({
  children = 'Contact Us',
  description,
  category,
  ...rest
}) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <StyledModal
        closeTimeoutMS={300}
        className="mainHeroModal"
        isOpen={open}
        onRequestClose={() => { setOpen(false) }}
        shouldCloseOnOverlayClick={true}
      >
        <CloseModalButton onClick={() => { setOpen(false) }}>
          <CloseIcon tw="w-6 h-6" />
        </CloseModalButton>
        <ContactUsForm centered description={description} hideImage category={category} />
      </StyledModal>
      {children({ open: setOpen })}
    </>
  )
}

ContactUsWrapper.propTypes = {}
ContactUsWrapper.defaultProps = {}

export default ContactUsWrapper
