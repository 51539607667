import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import navlinks from 'components/utils/navlinks'
import { PrimaryButton } from "components/misc/Buttons.js";
import TwoColWithTwoFeaturesAndButtons from "components/features/TwoColWithTwoFeaturesAndButtons";
import FactoryImage from 'images/factory.png'
import PlaneImage from 'images/plane.png'
import EarthImage from 'images/earth.png'
import contentful from "components/utils/contentful";
import WavingPersonImage from 'images/waving-person.png'
import ResponsiveVideoEmbed from "helpers/ResponsiveVideoEmbed.js";
import ContactUsWrapper from 'components/misc/ContactUsWrapper'
import { TOURS } from 'components/utils/categories'

const Container = tw.div`md:mb-20 lg:mb-40`

const MemberNetworkGraphic = styled.div`
  ${tw`relative`}

  .plane {
    ${tw`absolute`}
    bottom: -10rem;
    left: -10rem;
    transform: scale(2);
    z-index: -1;
  }
`
const ImageWrapper = styled.div`
  ${tw`flex flex-col mt-24`}

  .earth {
    ${tw`absolute top-0 right-0`}
  }
`

export default () => {
  const [video, setVideo] = useState(null)

  useEffect(() => {
    const getVideoLink = async () => {
      try {
        const res = await contentful.getEntries({
          content_type: 'asset',
          'fields.id': 'lctv-1',
        })

        setVideo(res.items[0].fields.asset.fields.file.url)
      } catch (err) {
        console.log(err)
      }
    }

    getVideoLink()
  }, [])

  return (
    <AnimationRevealPage disabled>
      <Header links={navlinks} />
      <Container>
        <TwoColWithTwoFeaturesAndButtons
          features={[]}
          subheading={null}
          heading={<span tw="uppercase text-primary-500">LeaseClub Tours</span>}
          description={
            <>
              <span>
                Travel plans in your future? Every year, our connections allow us to bring Lease Club members on guided tours to <b tw="text-secondary-300">prominent car factories in Europe</b>. We encourage you to take advantage of this rare opportunity to get to know more about your favourite vehicles and their history.
              </span>
              <ResponsiveVideoEmbed
                Component="video"
                controls
                loop
                muted
                autoPlay
                controlsList="nodownload"
                url={video}
                tw="mt-4 w-full"
              />
            </>
          }
          showBGImage={false}
          hideCTA
          imageComponent={(
            <ImageWrapper>
              <ContactUsWrapper description="Connect with us to get to know more about your favourite vehicles and their history" category={TOURS}>
                {
                  ({ open }) => (
                    <PrimaryButton tw="text-2xl px-8 py-4 self-center" onClick={() => { open(true) }}>Book Appointment</PrimaryButton>
                  )
                }
              </ContactUsWrapper>
              <MemberNetworkGraphic>
                <img src={FactoryImage} alt="LeaseClub Tours" />
                <img className="plane" src={PlaneImage} alt="LeaseClub Tours" />
              </MemberNetworkGraphic>
              <img className="earth" src={EarthImage} width={96} height={96} alt="LeaseClub Tours" />
            </ImageWrapper>
          )}
        />
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
