import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import navlinks from 'components/utils/navlinks'
import contentful from 'components/utils/contentful'
import ReactModalAdapter from "helpers/ReactModalAdapter";
import PhotosImage from 'images/photos.png'
import { useMediaQuery } from '@react-hook/media-query'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup, Dot } from 'pure-react-carousel'
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as ArrowRightIcon } from 'feather-icons/dist/icons/arrow-right.svg'
import { ReactComponent as ArrowLeftIcon } from 'feather-icons/dist/icons/arrow-left.svg'
import 'pure-react-carousel/dist/react-carousel.es.css';

const CarouselContainer = tw.div`relative mt-4 md:mt-8`
const Subheading = styled.p`
  ${tw`flex items-center justify-between sm:justify-start uppercase font-bold text-lg sm:text-2xl md:text-4xl text-primary-500`}

  img {
    ${tw`w-10 h-10 sm:w-20 sm:h-20 sm:ml-4`}
  }
`;
const Container = tw.div`max-w-screen-xl mx-auto pb-24`;
const SlideContent = tw.div`py-2 px-1 cursor-pointer relative`
const SlideImage = styled.img`
  ${tw`min-w-full h-full object-cover`}
  max-height: 316px;
  @media (max-width: 425px): {
    max-height: 150px;
  }
`

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed flex justify-center items-center inset-0 z-50`}

    transition: none;
    backdrop-filter: blur(2px) brightness(0.75);
  }
  &.mainHeroModal__content {
    ${tw`m-4 sm:m-16 max-w-screen-xl absolute sm:top-0 sm:bottom-0 flex justify-center items-center rounded-lg bg-white shadow-raised outline-none`}
  }
  .content {
    ${tw`h-full w-full p-1 lg:p-16`}
  }
  & img {
    ${tw`h-full w-full object-contain`}
  }
`;

const CloseIconWrapper = styled(CloseIcon)`
  ${tw`absolute cursor-pointer text-primary-400 bg-white rounded-full w-8 h-8 p-1`}
  top: 1rem;
  right: 1rem;
`

const SlideImageWrapper = (props) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <div>
      <SlideImage onClick={() => { setOpen(true) }} {...props} />
      <StyledModal
        className="mainHeroModal"
        isOpen={isOpen}
        onRequestClose={() => { setOpen(false) }}
        shouldCloseOnOverlayClick
      >
        <div className="content">
          <CloseIconWrapper onClick={() => { setOpen(false) }} />
          <img src={props.src} alt={props.alt} />
        </div>
      </StyledModal>
    </div>
  )
}

const ButtonBackContainer = styled(ButtonBack)`
  ${tw`absolute top-1/2 bg-primary-500 border-0 rounded-full flex items-center justify-center`}
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  left: -24px;
`

const ButtonNextContainer = styled(ButtonNext)`
  ${tw`absolute top-1/2 bg-primary-500 border-0 rounded-full flex items-center justify-center`}
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  right: -24px;
`

const DotGroupWrapper = styled(DotGroup)`
  ${tw`flex justify-center mt-4`}

  & > button {
    ${tw`px-0 py-0 mx-1 border-0 rounded-full bg-gray-500`}
    height: 12px;
    width: 12px;
  }

  & > button[disabled] {
    ${tw`bg-primary-500`}
  },
`

const Pictorials = () => {
  const [pictorials, setPictorials] = useState([])
  const matchesMobile = useMediaQuery('(max-width: 425px)')
  const matchesLgMobile = useMediaQuery('(max-width: 600px)')
  const matchesTablet = useMediaQuery('(max-width: 768px)')

  useEffect(() => {
    const getPictorials = async () => {
      try {
        const res = await contentful.getEntries({
          content_type: 'pictorial',
        })

        setPictorials(res.items.map((set) => {
          return {
            id: set.sys.id,
            title: set.fields.title,
            pictures: set.fields.pictures.map(pic => ({
              id: pic.sys.id,
              url: pic.fields.file.url,
            }))
          }
        }))
      } catch (err) {
        console.log(err)
      }
    }

    getPictorials()
  }, [])

  const getVisiblePhotos = () => {
    if (matchesMobile) return 1
    if (matchesLgMobile) return 2
    if (matchesTablet) return 3
    return 4
  }

  return (
    <Container>
      <Subheading><span>Living the Life of Luxury</span> <img src={PhotosImage} alt="Pictorials" /></Subheading>
      {
        pictorials.map(pictorial => (
          <CarouselContainer key={pictorial.id}>
            <CarouselProvider
              naturalSlideWidth={4000}
              naturalSlideHeight={2667}
              totalSlides={pictorial.pictures.length}
              visibleSlides={getVisiblePhotos()}
              dragEnabled={false}
              // isIntrinsicHeight
            >
              <Slider>
                {
                  pictorial.pictures.map((x, i) => (
                    <Slide key={x.id} index={i}>
                      <SlideContent>
                        <SlideImageWrapper draggable={false} src={x.url} alt={pictorial.title} />
                      </SlideContent>
                    </Slide>
                  ))
                }
              </Slider>
              <ButtonBackContainer>
                <ArrowLeftIcon tw="text-white w-8 h-8" />
              </ButtonBackContainer>
              <ButtonNextContainer>
                <ArrowRightIcon tw="text-white w-8 h-8" />
              </ButtonNextContainer>
              <DotGroupWrapper />
            </CarouselProvider>
          </CarouselContainer>
        ))
      }
    </Container>
  );
};

export default Pictorials