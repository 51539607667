import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import { PrimaryLink } from 'components/misc/Links'
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import navlinks from 'components/utils/navlinks'
import MessagingImage from 'images/messaging.png'

const FormBackground = styled.div`
  ${tw`relative py-12 md:py-20`}

  img {
    ${tw`absolute hidden md:block`}
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
  }
`

export default () => {
  return (
    <AnimationRevealPage disabled>
      <Header links={navlinks} />
      <FormBackground>
        <ContactUsForm
          hideImage
          description={<span>For enquiries, please email <PrimaryLink href="mailto:enquiry@solution.financial">enquiry@solution.financial</PrimaryLink></span>}
        />
        <img src={MessagingImage} alt="Contact enquiry@solution.financial for enquiries." />
      </FormBackground>
      <Footer />
    </AnimationRevealPage>
  );
};
