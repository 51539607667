import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Link as RouterLink } from 'react-router-dom'
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import Camera from 'images/camera.png'

const BackgroundContainer = styled.div`
  .bgOverlay {
    position: absolute;
    bottom: -6rem;
    z-index: -1;
  }
`

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-2xl mx-auto py-6 sm:py-20`;
const Column = tw.div`flex-1 w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`relative h-80 hidden md:h-auto lg:flex justify-center items-center`;
const TextColumn = styled(Column)(props => [
  tw`mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  `max-width: 85%;`,
  tw`absolute rounded bg-contain bg-no-repeat bg-center w-full h-full`
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-2xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.div`mt-4 text-center sm:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Features = tw.div`mt-8 max-w-sm mx-auto md:mx-0`;
const Feature = tw.div`mt-8 flex items-start flex-col md:flex-row`;

const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-center rounded-full p-2 flex-shrink-0`}
  svg {
    ${tw`w-5 h-5 text-primary-500`}
  }
`;

const FeatureText = tw.div`mt-4 md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`font-bold text-lg text-primary-500`;
const FeatureDescription = tw.div`mt-1 text-sm`;

const PrimaryButton = tw(PrimaryButtonBase)`text-sm inline-block mx-auto md:mx-0`;

const Link = styled(RouterLink)`
  ${tw`flex items-center justify-center sm:justify-start pb-3 cursor-pointer font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
`

const HexagonBackground = styled.div`
  position: relative;
  width: 500px;
  z-index: -1;
  height: 288.68px;
  background-color: rgb(85, 85, 85);

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 0;
    border-left: 250px solid transparent;
    border-right: 250px solid transparent;
  }

  &::before {
    bottom: 100%;
    border-bottom: 144.34px solid rgb(85, 85, 85);
  }

  &::after {
    top: 100%;
    width: 0;
    border-top: 144.34px solid rgb(85, 85, 85);
  }
`

export default ({
  subheading = "Our Network",
  heading = (
    <>
      Get on our <span tw="text-primary-500">exclusive</span> member network.
    </>
  ),
  description = "Sign up for our Lease Club Business Owner services to have your business featured on Solution Financial's network across the globe and get to know professionals from various industries.",
  primaryButtonText = "Contact Us",
  primaryButtonUrl = "https://timerse.com",
  linkText = 'Download our package',
  linkHref = '#',
  features = [
    {
      Icon: BriefcaseIcon,
      title: "Marketing Services",
      description: "Solution Lease Club also offers custom Marketing Services to help our clients grow their business."
    },
  ],
  textOnLeft = true,
  showBGImage = true,
  image = TeamIllustrationSrc,
  hideCTA = false,
  ButtonComponent = null,
  imageComponent = (
    <>
      <Image imageSrc={image} />
      <HexagonBackground />
    </>
  ),
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  /*
   * Change the features variable as you like, add or delete objects
   * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
   */

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          {imageComponent}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Features>
              {features.map((feature, index) => (
                <Feature key={index}>
                  <FeatureIconContainer>{<feature.Icon />}</FeatureIconContainer>
                  <FeatureText>
                    <FeatureHeading>{feature.title}</FeatureHeading>
                    <FeatureDescription>{feature.description}</FeatureDescription>
                  </FeatureText>
                </Feature>
              ))}
            </Features>
            {
              !hideCTA && (
                <BackgroundContainer>
                  {linkText ? <Link to={linkHref}>{linkText}</Link> : null}
                  {
                    ButtonComponent || (
                      <PrimaryButton as="a" href={primaryButtonUrl}>
                        {primaryButtonText}
                      </PrimaryButton>
                    )
                  }
                  {showBGImage && <img className="bgOverlay" src={Camera} alt="Member Services Background" />}
                </BackgroundContainer>
              )
            }
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
