import React, { useEffect, useState } from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/BackgroundAsImage.js";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import contentful from 'components/utils/contentful'

export default () => {
  const [landingVideo, setLandingVideo] = useState(null)

  useEffect(() => {
    const getLandingVideo = async () => {
      try {
        const res = await contentful.getEntries({
          content_type: 'link',
          'fields.id': 'lgv-1',
        })

        setLandingVideo(res.items[0].fields.link)
      } catch (err) {
        console.log(err)
      }
    }

    getLandingVideo()
  }, [])

  return (
    <AnimationRevealPage disabled>
      <Hero />
      <MainFeature
        subheading={null}
        statistics={[]}
        videoSrc={landingVideo}
        heading="WHO WE ARE"
        description="Solution Lease Club is a subsidiary of Solution Financial (TSX: SFI | OTCQX: SLNFF). We provide clients with special services and connecting them to our exclusive network."
        heading2="HOW IT WORKS"
        description2="Clients of Solution Financial are automatically enrolled as a Lease Club member. All members have immediate access to benefits and services offered by us and our partners."
      />
      <Footer />
    </AnimationRevealPage>
  )
};
